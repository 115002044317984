import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { Layout, SafeImage } from "../components/common";
import { processHtml } from "../utils/processHtml";

const Container = styled.div`
  padding: 2rem 0;
  @media ${props => props.theme.device.laptop} {
    padding: 3rem 0;
  }
  @media ${props => props.theme.device.laptopL} {
    padding: 5rem 0;
  }

  > img {
    margin: 1.25rem 0;
  }

  > h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 2rem 0;
    max-width: 100%;
  }
`;

const NotFoundPage = ({ data }) => {
  const { html, plaintext, slug } = data.ghostPage;
  const { htmlX, jumbotronData } = processHtml(html, plaintext);

  return (
    <Layout
      title={data.ghostPage.title}
      subtitle={jumbotronData.subtitle}
      meta={{
        title: data.ghostPage.meta_title,
        description: data.ghostPage.meta_description,
        canonical: data.ghostPage.url,
        featureImage: data.ghostPage.feature_image
      }}
      jumbotronVariant="basic"
      illustrations={
        <SafeImage
          className="smaller-illustration"
          alt="404 page illustration"
          src={`illustrations/pages/${slug}.svg`}
        />
      }
    >
      <Container className="full-width-container">{htmlX}</Container>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    ghostPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default NotFoundPage;

export const notFoundPageQuery = graphql`
  query {
    ghostPage(slug: { eq: "404" }) {
      html
      plaintext
      title
      meta_title
      meta_description
      url
      slug
      feature_image
    }
  }
`;
